.label {
  color: var(--gray-light) !important;
  font-weight: 500 !important;
}

.inputIcon {
  position: absolute;
  top: 36px;
  right: 15px;
}

.selectLabel{
  font-size: 12px;
  font-weight: 500;
  color: var(--gray);
}