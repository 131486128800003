@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

:root{
  --primary: #FF3838;
  --primary-light: #FFC7C7;
  --secondary: #FFF5F5;
  --font-color: #434343;
  --gray: #797979;
  --gray-light: #969696;
  --gray-lighter: #DFDFDF;
  --bg-color: #FFFFFF;
  --bg-light-gray: #F9F9F9;
  --shadow-primary: #FF383863;
  --shadow-gray: #0000003D;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font-color);
}

main{
  min-height:calc(100vh - 40px - 100px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-toggler {
  display: block !important;
}

.btn {
  border-radius: 8px;
  font-weight: 500;
  &-primary{
    background-color: var(--primary);
    border-color: var(--primary);
    box-shadow: 0px 3px 6px var(--shadow-primary);
    &:focus, &:active, &:hover{
      background-color: #bf1f1f !important;
      border-color: var(--primary) !important;
    }
    &:disabled{
      background-color: rgba(255, 56, 56, .4) !important;
      border: none;
    }
  }
  &-secondary{
    background-color: var(--bg-color);
    border-width: 2px;
    border-color: var(--primary);
    color: var(--font-color);
    box-shadow: 0px 3px 6px var(--shadow-gray);
  }
  &-link-primary{
    border: none;
    color: var(--primary);
  }
  &-MercadoPago {
    background-color: #00B0EF;
    border-color: #00B0EF;
    color: #fff;
    box-shadow: 0px 3px 6px #00000012;
  }
}

.primary {
  color: var(--primary);
}
.gray {
  color: var(--gray);
}
.light-gray{
  color: var(--gray-light);
}

.fs-15{
  font-size: 0.9375rem;
}

.title-secondary {
  background-color: var(--bg-color);
  border: 2px solid var(--primary);
  color: var(--font-color);
  padding: 10px 15px;
  border-radius: 8px;
  font-weight: 500;
}

.smaller{
  font-size: 14px;
}

.navbar-collapse {
  transition: transform 0.15s ease-in-out;
  transform: translateX(100%);
  &.show{
    transform: translateX(0);
  }
}

.carousel.carousel-slider{
  height: 510px;
}

.pagination .page-item .page-link{
  color: var(--primary);
  font-weight: 500;
  font-size: 20px;
  padding: 5px 20px;
  border-color: var(--primary);

}
.pagination .page-item.active>.page-link{
  background-color: var(--primary) !important;
  border-color: #bf1f1f;
  color: white;
  font-weight: 600;
}

@media(min-width: 567px){
  .container{
    max-width: 100vw;
  }

  .containerLogin {
    margin: 0 20%;
  }
    .carousel.carousel-slider{
    width: 700px !important;
  }
  .carousel-root{
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
