.navbar{
  width: 100%;
  >.container{
    z-index: 10;
    box-shadow: 0px 4px 8px #0000000F;

    .toggler{
      transition: all .25s ease-in-out;
      &:focus{
        box-shadow: none !important;
        transform: scale(1.11);
      }
      
    }
  
    .navbarCollapse{
      position: fixed;
      overflow-y: scroll !important;
      width: 87vw;
      // height: calc(100vh - 72px);
      bottom: 0;
      top: 74px;
      right: 0;
      z-index: 1;
      background: var(--bg-color);
      box-shadow: 0px 70px 18px #0000004A;
      transition: all .15s ease-in-out;
      &.show{
        transition: display .15s ease-in-out;
      }  
  
      .navLink{
        border-radius: 8px;
        background-color: var(--gray-lighter);
        height: 50px;
        padding: 15px 12px;
        margin-bottom: 1rem;
        font-size: 0.8125rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
.userEclipse {
  position: relative;
  display: flex;
  align-items: center;

  .userInitials{
    color: var(--primary);
    font-weight: 500;
    font-size: 18.5px;
    position: absolute;
    left: 35%;
    top: 25%;
  }
}

.profile{
  width: 120px;
  height: 120px;

  img{
    width: 120px;
  }
  .userInitials{
    font-size: 2em;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

@media(min-width: 992px){
  .navbar{
    >.container{
      .navbarCollapse{
        width: 35vw;
      }
    }
  }
}